<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <ValidationObserver ref="form"  v-slot="{ handleSubmit }">
              <b-form  @submit.prevent="handleSubmit(register)" >
                <b-row>
                  <b-col lg="10" sm="12">
                    <ValidationProvider name="Organization" vid="org_id" rules="required|min_value:1">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="organization"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                        {{ $t('org_pro.organization') }} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                          plain
                          v-model="circleArea.org_id"
                          :options="organizationList"
                          id="org_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                            <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Division" vid="division_id" rules="required|min_value:1">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="division"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                        {{ $t('org_pro_division.division') }} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                          plain
                          v-model="circleArea.division_id"
                          :options="divisionList"
                          id="division_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                            <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="District" vid="district_id" rules="required|min_value:1">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="district"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                        {{ $t('org_pro_district.district') }} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                          plain
                          v-model="circleArea.district_id"
                          :options="districtList"
                          id="district_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                            <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Circle Area Name (En)" vid="circle_area_name" rules="required">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="circle_area_name"
                          slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('irrigation_config.circle_area_name')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                            id="circle_area_name"
                            v-model="circleArea.circle_area_name"
                            :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Circle Area Name (Bn)" vid="circle_area_name_bn" rules="required">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="circle_area_name_bn"
                          slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('irrigation_config.circle_area_name_bn')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                            id="circle_area_name_bn"
                            v-model="circleArea.circle_area_name_bn"
                            :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </b-row>
                <div class="row">
                  <div class="col-sm-3"></div>
                  <div class="col text-right">
                    <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                    &nbsp;
                    <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                  </div>
                </div>
              </b-form>
            </ValidationObserver>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { irriSchemeServiceBaseUrl } from '../../../../../config/api_config'
import { circleAreaStore, circleAreaUpdate } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      circleArea: {
        org_id: '0',
        division_id: '0',
        district_id: '0',
        circle_area_name: '',
        circle_area_name_bn: ''
      },
      districtList: []
    }
  },
  created () {
    if (this.id) {
      const tmp = this.getcircleAreaData()
      this.circleArea = tmp
    }
  },
  mounted () {
    core.index()
  },
  computed: {
    organizationList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    }
  },
  watch: {
    // 'circleArea.org_id': function (newVal, oldVal) {
    //   this.getProjectlist(newVal)
    //   this.getPumplist(newVal)
    // },
    'circleArea.division_id': function (newVal, oldVal) {
      this.districtList = this.getDistrictList(newVal)
    }
  },
  methods: {
    getcircleAreaData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async register () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }

      if (this.circleArea.id) {
        result = await RestApi.putData(irriSchemeServiceBaseUrl, `${circleAreaUpdate}/${this.id}`, this.circleArea)
      } else {
        result = await RestApi.postData(irriSchemeServiceBaseUrl, circleAreaStore, this.circleArea)
      }
      loadinState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('IrriConfig/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? 'Data updated successfully' : 'Data save successfully',
          color: '#D6E09B'
        })
        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
        this.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
      }
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
      if (divisionId) {
        return districtList.filter(district => (district.division_id === divisionId && district.status === 0))
      }
      return districtList
    }
  }
}
</script>
